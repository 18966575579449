@keyframes showup {
    0% {
        transform: translate(20px, -40px);
        animation-timing-function: ease-out;
        opacity: 0;
    }

    100% {
        transform: translate(-40px, -40px);
        opacity: 1;
    }
}

.cookie__wrapper {
    position: fixed;
    z-index: 100;
    padding: 16px;
    background-color: #fdc468;
    color: black;
    width: 300px;
    right: 0;
    transform: translate(-40px, -40px);
    border-radius: 10px;
    box-shadow: 0 2px 5px #bcbbbb;
    animation: showup 1s ease-out;
}

.cookie__text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 22px;
}

.cookie__accept {
    display: inline-flex;
    align-items: center;
    position: relative;

    touch-action: manipulation;
    user-select: none;
    letter-spacing: 0.1em;

    /**
  * Remove border radius on iOS buttons
  */
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    background: transparent;
    font-weight: bold;
    text-transform: uppercase;

    transition: color 0.15s ease-out, background 0.15s ease-out;

    background: #fa2c33;
    color: white;

    padding: 8px 10px;
    border-radius: 20px;
    font-size: 11px;
    line-height: 16px;
    border: none;
}

.cookie__accept:hover,
.cookie__accept:focus {
    color: white;
    background: #fc7078;
}

.cookie__link {
    font-size: 16px;
    line-height: 22px;
    color: #fa2c33;
    opacity: 0.9;
    margin-bottom: 8px;
    transition: opacity 0.15s ease-out;
}

.cookie__link:hover {
    opacity: 0.6;
}
