.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 200ms linear;
}

.page-exit {
    opacity: 1;
    position: absolute;
    top: -72px;
}

.page-exit-active {
    opacity: 0;
}
